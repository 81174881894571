import uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-right.svg'
import { lg } from '../utils/breakpoints'
import { RequiredHTMLType } from '../utils/prop-types'
import Heading from './heading'
import Link from './link'
import Select from './select'
import { inlineTypography } from './text'

const Row = styled.div`
  ${tw`flex flex-row justify-between items-center py-6`}
  ${lg} {
    ${tw`grid`}
    grid-template-columns: 50% 20% 20% 10%;
  }
`

const ColumnHeader = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  opacity: 0.4;
  text-transform: uppercase;
`

const PositionList = ({
  positions,
  positionsPlaceholder,
  positionsPositionLabel,
  positionsFunctionLabel,
  positionsLocationLabel,
  positionsAreasLabel,
  positionsLocationsLabel,
}) => {
  const lobOptions = useMemo(
    () => [
      { label: positionsAreasLabel, value: 'all' },
      ...(positions && positions.length
        ? uniqBy(positions, 'lob').map((p) => ({ label: p.lob, value: p.lob }))
        : []),
    ],
    [positions]
  )

  const countryOptions = useMemo(
    () => [
      { label: positionsLocationsLabel, value: 'all' },
      ...(positions && positions.length
        ? uniqBy(positions, 'country').map((p) => ({ label: p.country, value: p.country }))
        : []),
    ],
    [positions]
  )

  const [selectedLob, setSelectedLob] = useState(lobOptions.length ? lobOptions[0] : null)

  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.length ? countryOptions[0] : null
  )

  const filteredPositions = useMemo(
    () =>
      positions.filter(
        (position) =>
          (selectedCountry.value === 'all' || position.country === selectedCountry.value) &&
          (selectedLob.value === 'all' || position.lob === selectedLob.value)
      ),
    [positions, selectedCountry, selectedLob]
  )

  return (
    <div css={tw`flex flex-col items-center w-full`}>
      <div css={tw`flex flex-row space-x-4 w-full mb-12 lg:(max-w-1/2 mb-16)`}>
        <Select name="lob" value={selectedLob} onChange={setSelectedLob} options={lobOptions} />
        <Select
          name="country"
          value={selectedCountry}
          onChange={setSelectedCountry}
          options={countryOptions}
        />
      </div>
      <div css={tw`flex flex-col w-full divide-y divide-line-grey`}>
        <Row css={tw`py-4 lg:py-5`}>
          <ColumnHeader>{positionsPositionLabel}</ColumnHeader>
          <ColumnHeader css={tw`hidden lg:block`}>{positionsFunctionLabel}</ColumnHeader>
          <ColumnHeader css={tw`hidden lg:block`}>{positionsLocationLabel}</ColumnHeader>
        </Row>
        {filteredPositions.map((position, i) => (
          <Link key={i} to={`/careers/${position.originalId}`}>
            <Row
              css={css`
                ${tw`py-6 hidden lg:grid`}
                &:hover {
                  svg {
                    ${tw`lg:(transform translate-x-4)`}
                  }
                }
              `}
            >
              <div css={tw`font-bold text-primary-500`}>{position.title}</div>
              <div>{position.lob}</div>
              <div>{position.country}</div>
              <div css={tw`flex items-center justify-self-end mr-10`}>
                <Caret css={tw`transition-transform duration-300 ease-in-out`} />
              </div>
            </Row>
            <Row css={tw`lg:hidden`}>
              <div css={tw`flex flex-col`}>
                <div css={tw`font-bold text-primary-500`}>{position.title}</div>
                <div>{`${position.lob} - ${position.country}`}</div>
              </div>
              <Caret />
            </Row>
          </Link>
        ))}
        {!filteredPositions.length ? (
          <Heading
            headingType="h5"
            content={positionsPlaceholder}
            style={[
              inlineTypography,
              css`
                ${tw`pt-4 lg:pt-5 text-center text-primary-500`}
              `,
            ]}
          />
        ) : null}
      </div>
    </div>
  )
}

PositionList.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object),
  positionsPlaceholder: RequiredHTMLType,
  positionsPositionLabel: PropTypes.string.isRequired,
  positionsFunctionLabel: PropTypes.string.isRequired,
  positionsLocationLabel: PropTypes.string.isRequired,
  positionsAreasLabel: PropTypes.string.isRequired,
  positionsLocationsLabel: PropTypes.string.isRequired,
}

export default PositionList
