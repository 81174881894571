import PropTypes from 'prop-types'
import React from 'react'

const Section = ({ rendered, children, ...props }) => {
  if (!rendered) return null
  return <section {...props}>{children}</section>
}

Section.defaultProps = {
  rendered: true,
}

Section.propTypes = {
  rendered: PropTypes.bool,
  children: PropTypes.node,
}

export default Section
