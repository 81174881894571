import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="bottom-left" width={776} height={116}>
        <Rectangle x={608} y={0} width={168} height={28} fill="yellow" />
      </Overlay>
      <Overlay visibility="desktop" position="right" width={336} height={252}>
        <Rectangle x={0} y={168} width={112} height={56} fill="white" />
        <Rectangle x={112} y={224} width={28} height={28} fill="navy" />
      </Overlay>
      <Overlay visibility="mobile" position="bottom-left" width={55.76} height={72}>
        <Rectangle x={0} y={0} width={55.76} height={18.75} fill="yellow" />
      </Overlay>
      <Overlay visibility="mobile" position="right" width={75} height={168.25}>
        <Rectangle x={0} y={112} width={56.25} height={37.5} fill="white" />
        <Rectangle x={56.25} y={149.5} width={18.75} height={18.75} fill="navy" />
      </Overlay>
    </>
  ),
}
