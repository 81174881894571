import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const Values = ({ values, style }) => {
  return (
    <div css={[tw`flex flex-col space-y-16 lg:(flex-row space-y-0 space-x-20)`, style]}>
      {values.map((value) => (
        <div key={value.title} css={tw`flex flex-col flex-auto items-start lg:w-1/4`}>
          <div css={tw`w-16 h-16 lg:(w-32 h-32)`}>
            <Image image={value.image} style={tw`w-full h-full`} />
          </div>
          <Heading
            headingType="h5"
            content={value.title}
            style={tw`text-primary-500 font-bold mt-8 lg:mt-12`}
          />
          <Text content={value.descriptionNode} style={tw`mt-4`} />
        </div>
      ))}
    </div>
  )
}

Values.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default Values
